<template>
  <svg class="animate-spin" width="42" height="42" viewBox="0 0 42 42" fill="none">
    <path
      d="M36.75 32.0709C34.1072 35.8317 30.2061 38.5226 25.7519 39.6573C21.2977 40.7919 16.5845 40.2953 12.4646 38.2573C8.34475 36.2192 5.09007 32.7743 3.28914 28.5454C1.4882 24.3164 1.25988 19.5827 2.64547 15.2001C4.03106 10.8175 6.93908 7.0753 10.8437 4.65023C14.7483 2.22516 19.3918 1.27727 23.9345 1.97796C28.4772 2.67866 32.6193 4.98168 35.6117 8.4706C38.6041 11.9595 40.2494 16.404 40.25 21.0004"
      stroke="#D70036"
      stroke-width="2"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
  </svg>
</template>
