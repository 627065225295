<template>
  <div>
    <div class="grid grid-cols-12 gap-4 lg:gap-8">
      <template v-for="(_, idx) in data" :key="idx">
        <ArticleListFeaturedItem
          v-if="showFeatured && Number(idx) === 0 && typeName === 'SectionArticleListRecord'"
          :data="getExposedArticle()"
          :class="'col-span-12 mb-10 lg:col-span-12 lg:mb-16'"
          :type-name="typeName"
        />
      </template>

      <template v-for="(item, index) in data" :key="item.id">
        <ArticleListItem
          v-if="item?.id !== exposedArticleRef?.id && typeName === 'SectionArticleListRecord'"
          :data="item"
          :index="index"
          :is-related-article="isRelatedArticle"
          class="col-span-12 md:col-span-6 lg:col-span-4"
          :type-name="typeName"
        />

        <ProjectListItem
          v-if="item?.id !== exposedArticleRef?.id && typeName === 'SectionProjectListRecord'"
          :data="item"
          :index="index"
          :is-related-article="isRelatedArticle"
          class="col-span-12 md:col-span-6 lg:col-span-4"
          :type-name="typeName"
        />
      </template>
    </div>

    <div v-if="isLoading" class="py-10 text-center">
      <Spinner class="mx-auto mt-10" />
    </div>

    <div v-else-if="loadMore" class="text-center">
      <hr class="text-neutral-100 my-14" />
      <Button
        size="large"
        variant="secondary"
        :label="$t('articles.more')"
        @click="store.fetchArticles(locale, categoryID)"
      />
    </div>

    <div v-else-if="isEmpty">
      <div class="my-10 text-center text-4xl">{{ $t('articles.empty') }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useBlogStore } from '~/stores/BlogStore'
import { useProjectStore } from '~/stores/ProjectStore'

enum TYPE_NAMES {
  PROJECT_LIST = 'SectionProjectListRecord',
  ARTICLE_LIST = 'SectionArticleListRecord'
}

const { locale } = useI18n()
const route = useRoute()

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  loadMore: {
    type: Boolean,
    default: false
  },
  isEmpty: {
    type: Boolean,
    default: false
  },
  showFeatured: {
    type: Boolean,
    default: false
  },
  isRelatedArticle: {
    type: Boolean,
    default: false
  },
  typeName: {
    type: String,
    default: 'SectionArticleListRecord'
  }
})

// Dynamic store usage based on module type
const store = TYPE_NAMES.PROJECT_LIST === props.typeName ? useProjectStore() : useBlogStore()
const categoryID = computed(() => store.getCategoryByName(route.query.category)?.id)

const { data: exposedArticles } = await useAsyncGql('exposedArticles', {
  // @ts-ignore
  options: {
    initialCache: false
  }
})

const allExposedArticles = computed(() => exposedArticles.value?.allExposedArticles)

const commonExposedArticle = computed(
  () => allExposedArticles.value?.find(article => !article?.category)
)
const categoryExposedAvailable = computed(
  () =>
    allExposedArticles.value?.find(
      article => article.category && article.category?.name === route.query.category
    )
)
const allArticlesExposed = computed(() => route.params.slug === 'news' && !route.query.category)
const slugArticlesExposed = computed(
  () =>
    allExposedArticles.value?.find(article =>
      route.params.slug.includes(article.category?.name?.toLowerCase() as string)
    )
)
const exposedArticleRef = ref()

function getExposedArticle() {
  let exposedArticle = props.data[0]

  if (categoryExposedAvailable.value) {
    exposedArticle = categoryExposedAvailable.value.article
  } else if (commonExposedArticle.value && allArticlesExposed.value) {
    exposedArticle = commonExposedArticle.value.article
  } else if (slugArticlesExposed.value) {
    exposedArticle = slugArticlesExposed.value.article
  }

  exposedArticleRef.value = exposedArticle

  return exposedArticle
}
</script>
