<template>
  <div class="col-span-12 lg:col-span-4" :class="isRelatedArticle && 'flex gap-4 md:block'">
    <NuxtLink
      class="relative block w-full basis-1/2"
      :to="localePathByType(moduleType, data.slug)"
      noPrefetch
    >
      <div
        v-if="isRelatedArticle"
        class="absolute left-6 top-6 z-2 transform-gpu text-2xl lg:text-4xl"
        :class="[
          index % 2 !== 0 ? 'text-white' : 'text-neutral-900',
          isRelatedArticle && 'basis-1/2'
        ]"
      >
        0{{ index + 1 }}
      </div>

      <div class="overflow-hidden rounded-[40px]">
        <Image
          v-if="data.image"
          loading="lazy"
          class="rounded-[40px] object-cover !transition-all !duration-300 hover:scale-110"
          :class="isRelatedArticle ? 'aspect-square' : 'aspect-4/3'"
          sizes="(min-width: 1920px) 597px, (min-width: 1400px) 405px, (min-width: 1040px) calc(26.47vw + 40px), calc(50vw - 24px)"
          :src="data.image.responsiveImage ? data.image.responsiveImage?.src : data.image.url"
          :srcset="data.image.responsiveImage ? data.image.responsiveImage?.webpSrcSet : undefined"
          :width="data.image.responsiveImage ? data.image.responsiveImage.width : data.image.width"
          :height="
            data.image.responsiveImage ? data.image.responsiveImage.height : data.image.height
          "
          :alt="data.image.alt ? data.image.alt : ''"
        />
      </div>
    </NuxtLink>

    <NuxtLink
      :to="localePathByType(moduleType, data.slug)"
      class="block text-xl text-neutral-800 lg:my-7 lg:text-2xl"
      :class="isRelatedArticle ? 'my-0 basis-1/2 md:mb-4 md:mt-5' : 'mb-4 mt-5'"
      noPrefetch
      >{{ data.title }}</NuxtLink
    >
  </div>
</template>

<script setup lang="ts">
const { localePathByType } = useLocalePathByType()

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  index: {
    type: Number,
    required: true
  },
  isRelatedArticle: {
    type: Boolean,
    default: false
  },
  typeName: {
    type: String,
    default: 'SectionArticleListRecord'
  }
})

const moduleType = computed(() =>
  props.typeName === 'SectionArticleListRecord' ? 'ArticleRecord' : 'ProjectRecord'
)
</script>
