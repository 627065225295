<template>
  <div
    class="relative pb-32 pt-14 text-neutral-800 before:absolute before:-left-44 before:hidden before:h-full before:w-[calc(100%+200px)] lg:pb-32 lg:pt-16 lg:before:block lg:before:w-[calc(100%+300px)] 2xl:before:-left-80 2xl:before:w-[calc(100%+600px)] hd:before:-left-44 hd:before:w-[calc(100%+300px)]"
  >
    <div class="grid-default gap-y-5 lg:items-center">
      <NuxtLink
        v-if="data.image"
        :to="localePathByType(moduleType, data.slug as string)"
        class="col-span-12 lg:col-span-6"
      >
        <div class="overflow-hidden rounded-[32px] lg:rounded-[40px]">
          <Image
            v-if="data.image"
            class="aspect-4/3 rounded-[32px] object-cover !transition-all !duration-300 hover:scale-110 lg:rounded-[40px]"
            :src="data.image.responsiveImage ? data.image.responsiveImage?.src : data.image.url"
            :srcset="
              data.image.responsiveImage ? data.image.responsiveImage?.webpSrcSet : undefined
            "
            :width="912"
            :height="684"
            :alt="data.image.alt ? data.image.alt : ''"
          />
        </div>
      </NuxtLink>
      <div class="relative z-10 col-span-12 lg:col-start-8 lg:col-end-13">
        <h1 v-if="data.title" class="relative z-2 mb-4 text-3xl lg:mb-5 lg:text-5xl">
          {{ data.title }}
        </h1>
        <div
          v-if="data.excerpt"
          class="prose relative z-2 mb-6 text-base lg:text-xl"
          v-html="data.excerpt"
        />
        <Button
          v-if="data.slug"
          :label="$t('more')"
          filled
          :size="isLG ? 'large' : 'medium'"
          :to="localePathByType(moduleType, data.slug as string)"
          theme="dark"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { theme } from '#tailwind-config'

const { localePathByType } = useLocalePathByType()
const breakpoints = useBreakpoints(theme.screens)
const isLG = breakpoints.greater('lg')

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  typeName: {
    type: String,
    default: 'SectionArticleListRecord'
  }
})

const moduleType = computed(() =>
  props.typeName === 'SectionArticleListRecord' ? 'ArticleRecord' : 'ProjectRecord'
)
</script>
